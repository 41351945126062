(function ($) {

    // $.proofguide('/wp-content/themes/pp-v1.2-children/rodney-ross/resources/guide2.jpg');


    // PARALLAX STARTER 
    $.getScript("https://d3sw26zf198lpl.cloudfront.net/2017/10/03/59d3e983405be.js", function () {
        var s = skrollr.init({
            render: function (data) {
                //Debugging - Log the current scroll position.
                //console.log(data.curTop);
            }
        });
        if (s.isMobile()) {
            s.destroy();
        }

    });

    $('#bcorr-section-1 .bcorr-content').attr('data-0', 'transform: translateX(300px); opacity: 0;');
    $('#bcorr-section-1 .bcorr-content').attr('data--50-bottom', 'transform: translateX(0); opacity: 1;');
    $('#bcorr-section-1 .bcorr-content').attr('data-anchor', '#bcorr-section-1');

    $('#bcorr-section-1 .bcorr-agent-wrapper').attr('data-0', 'transform: translateX(-300px); opacity: 0;');
    $('#bcorr-section-1 .bcorr-agent-wrapper').attr('data--50-bottom', 'transform: translateX(0); opacity: 1;');
    $('#bcorr-section-1 .bcorr-agent-wrapper').attr('data-anchor', '#bcorr-section-1');

    $('#bcorr-section-3 .bcorr-content').attr('data-0', 'transform: translateX(-300px); opacity: 0;');
    $('#bcorr-section-3 .bcorr-content').attr('data--50-bottom', 'transform: translateX(0); opacity: 1;');
    $('#bcorr-section-3 .bcorr-content').attr('data-anchor', '#bcorr-section-3');

    $('#bcorr-section-3 .bcorr-area-img').attr('data-0', 'transform: translateX(300px); opacity: 0;');
    $('#bcorr-section-3 .bcorr-area-img').attr('data--50-bottom', 'transform: translateX(0); opacity: 1;');
    $('#bcorr-section-3 .bcorr-area-img').attr('data-anchor', '#bcorr-section-3');

    $('#bcorr-section-5 .bcorr-content').attr('data-0', 'transform: translateX(-300px); opacity: 0;');
    $('#bcorr-section-5 .bcorr-content').attr('data--50-bottom', 'transform: translateX(0); opacity: 1;');
    $('#bcorr-section-5 .bcorr-content').attr('data-anchor', '#bcorr-section-5');

    $('#bcorr-section-6 .bcorr-content-left').attr('data-0', 'transform: translateX(-300px); opacity: 0;');
    $('#bcorr-section-6 .bcorr-content-left').attr('data--50-bottom', 'transform: translateX(0); opacity: 1;');
    $('#bcorr-section-6 .bcorr-content-left').attr('data-anchor', '#bcorr-section-6');

    $('#bcorr-section-6 .bcorr-content-right').attr('data-0', 'transform: translateY(600px); opacity: 0;');
    $('#bcorr-section-6 .bcorr-content-right').attr('data--50-bottom', 'transform: translateX(0); opacity: 1;');
    $('#bcorr-section-6 .bcorr-content-right').attr('data-anchor', '#bcorr-section-6');

})(jQuery);

